@import "../fonts";

div.iona-checkbox-wrap {
    padding: 20px;
}

div.iona-checkbox-wrap > p {
    margin-left: 48px;
}

label.iona-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;

    font-size: 22px;
    @include font-bold;

    cursor: pointer;
}

input[type="checkbox"] {
    appearance: none;
    width: 35px;
    height: 35px;

    margin-right: 15px;
    border-radius: 50%;

    display: block;
    border: 2px solid #D9D9D9;
    cursor: pointer;
}

input[type="checkbox"]:checked {
    color: black;
    background-color: #f59b01;
    mask-image: url("~src/assets/img/icons/checked.svg");
    mask-size: contain;
    border-color: #f59b01;
}
