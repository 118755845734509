@import "../layout-new"
@import "../colors"
@import "../type"

$input-height: 55px
$input-border-radius: calc(#{$input-height} / 2)

$input-height-small: 32px

$input-font-size-regular: 22px

$input-border-color: white
$input-placeholder-font-color: #333
$input-icon-color: black


// fieldset
fieldset.input
    position: relative
    flex: 1
    min-width: 300px

    margin: 0
    padding: 0

    border: 0
    background-color: transparent

    @include row

    @include font-light

    color: #777

    &:focus
        border-color: $iona-orange
        background-color: transparent !important

// input
fieldset.input > input
    height: $input-height
    width: 100%

    margin: 0
    padding: 10px 50px 10px $input-border-radius

    border: 2px solid $input-border-color
    border-radius: $input-border-radius

    font-size: 22px
    box-sizing: border-box

    background-color: transparent

    color: white

    &:focus
        border-color: $iona-orange
        outline: $iona-orange

    &::placeholder,
    &::-webkit-input-placeholder
        @include font-regular
        color: #777777


fieldset.input > input:-webkit-autofill,
fieldset.input > input:-webkit-autofill:hover,
fieldset.input > input:-webkit-autofill:focus,
fieldset.input > input:-webkit-autofill:active
    transition: background-color 5000s ease
    -webkit-text-fill-color: #fff !important
    font-family: "Innogy regular", sans-serif

// legend
fieldset.input > legend
    display: none

// remove term
fieldset.input > i
    position: absolute
    right: 20px
    bottom: 0
    top: 17px
    height: 100%
    background-color: white
    cursor: pointer

    transition: background-color 100ms ease

    &:hover
        background-color: $iona-orange
