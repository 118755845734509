@import "../fonts";

.detail-view {
    @include font-regular;
}

/// head
section.detail-head {
    background: #f6f6f7;
    padding: 50px;
}

.detail-head > header {
    display: flex;
    flex-direction: row;

    h1 {
        margin: 0;
        flex: 1 0 auto;
        @include font-bold;
        font-size: 40px;
        color: white;
    }

    button {
        margin-left: 20px;
    }
}

.detail-head > section {
    padding: 30px;
    display: flex;
    flex-direction: column;
}

/// INFO
section.detail-info {
    padding-top: 30px;
    padding-right: 50px;
    padding-left: 50px;
    background: #ffc300;

    display: flex;
    flex-direction: row;
}

.detail-info {
    background: #ffc300;
}

.detail-info > .icon {
    flex: 0;
    margin-right: 32px;
    //margin-right: 30px;
}

.detail-info > .info-text {
    p {
        @include font-regular;
        font-size: 18px;
    }
}

.detail-info > .close-info {
    flex: 0;
}

/// CONTENT
section.detail-content {
    padding-top: 30px;
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 50px;
}

