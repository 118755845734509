@import "../type";

.tab-selector {
    width: 100%;
    display: flex;
    flex-direction: row;

    margin-bottom: 10px;

    @include font-medium;

    > div {
        width: 50%;
        cursor: pointer;

        padding-bottom: 15px;
        padding-top: 15px;

        border-bottom: 2px solid #8ed0d8;

        text-align: center;
        color: #8ed0d8;
    }

    .active {
        color: white;
        border-bottom: 2px solid white;
    }
}
