@import "../colors";
@import "../fonts";

$input-height-regular: 55px;
$input-height-small: 32px;
input.iona-input {
    width: 100%;
    height: $input-height-regular;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: calc(#{$input-height-regular} / 2);
    padding-right: calc(#{$input-height-regular} / 2);

    font-size: 22px;
    border: 2px solid #D9D9D9;
    border-radius: calc(#{$input-height-regular} / 2);

    background-color: transparent;

    font-family: "Innogy regular", sans-serif !important;

    color: #777;

    &:focus {
        border-color: $iona-orange;
        background-color: transparent !important;
    }
}

.iona-input.white {
    color: white;
    border-color: white;
}

.iona-input.grey {
    color: white;
    background: rgba(0, 0, 0, 0.2);
}

.iona-input.grey::placeholder {
    @include font-light;
    color: white;
}

input.iona-input.white:-webkit-autofill,
input.iona-input.white:-webkit-autofill:hover,
input.iona-input.white:-webkit-autofill:focus,
input.iona-input.white:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #fff !important;
    font-family: "Innogy regular", sans-serif;
}

input.iona-input > i {
    position: absolute;
    right: 10px;
    top: 0;
    cursor: pointer;
}

/**
 * SELECTS
 * ==============================================================================
 */

@mixin iona-select($input-height: $input-height-regular, $size: regular) {
    width: auto;
    height: $input-height;
    @if ($size == regular) {
        padding: 10px calc(#{$input-height} / 2);
    } @else {
        padding: 0 calc(#{$input-height} / 2);
        padding-right: 40px;
    }
    border: 2px solid #333333;
    border-radius: calc(#{$input-height} / 2);

    font-family: "Innogy regular", sans-serif !important;
    @if ($size == regular) {
        font-size: 22px;
    } @else {
        font-size: 18px;
    }
    color: #777;

    background-image: url("~src/assets/img/icons/chevron-down.svg");
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-color: transparent;

    &:focus {
        border-color: $iona-orange;
        background-color: transparent !important;
    }
}

select.iona-select {
    @include iona-select($input-height-regular, regular)
}

select.iona-select.small {
    @include iona-select($input-height-small, small)
}


