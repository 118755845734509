@charset "utf-8";

.inline ul {
    margin: 0;
    padding: 0
}

.inline ul li {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none
}

.inline ul li a {
}

.inline ul li a:hover {
}

a#hamburger {
    display: none;
    background: #000;
}

a#hamburger .burger {
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
    -webkit-transform-style: preserve-3d;
}

a#hamburger .burger i {
    width: 22px;
    height: 2px;
    background: #fff;
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -11px;
    top: 50%;
    margin-top: -1px;
    -webkit-transition: -webkit-transform 350ms cubic-bezier(0.2, 1, 0.3, 1), margin 350ms cubic-bezier(0.2, 1, 0.3, 1);
    -moz-transition: -moz-transform 350ms cubic-bezier(0.2, 1, 0.3, 1), margin 350ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 350ms cubic-bezier(0.2, 1, 0.3, 1), margin 350ms cubic-bezier(0.2, 1, 0.3, 1);
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
}

a#hamburger .burger i.top {
    margin-top: -7px
}

a#hamburger .burger i.middle {
}

a#hamburger .burger i.bottom {
    margin-top: 5px
}

a#hamburger .text {
    display: none
}

a#hamburger.open .burger {
}

a#hamburger.open .burger i.top {
    margin-top: -1px;
    -webkit-transform: rotate(45deg) translate3d(0, 0, 0);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

a#hamburger.open .burger i.middle {
    -webkit-transform: scale(0, 1) translate3d(0, 0, 0);
    -moz-transform: scale(0, 1);
    -o-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
}

a#hamburger.open .burger i.bottom {
    margin-top: -1px;
    -webkit-transform: rotate(-45deg) translate3d(0, 0, 0);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/* -- Hauptnavi -- */
#mainmenu {
}

#mainmenu ul {
}

#mainmenu ul li {
}

#mainmenu ul li a {
}

#mainmenu ul li a:hover {
}

/* -- Topmenu -- */
#topmenu {
    float: right
}

#topmenu ul {
}

#topmenu ul li {
    margin-left: 20px
}

#topmenu ul li a {
}

#topmenu ul li a:before {
    position: relative;
    top: 3px;
    margin-right: 10px
}

#topmenu ul li a:hover {
}

#topmenu ul li a.disabled {
    pointer-events: none;
    filter: alpha(opacity=30);
    opacity: 0.3;
    cursor: default
}

/* -- Footermenu -- */
#footermenu {
}

#footermenu ul {
}

#footermenu ul li {
    margin-left: 20px
}

#footermenu ul li a {
    text-decoration: none
}

#footermenu ul li a:hover {
    text-decoration: underline
}
