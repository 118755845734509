@import "../colors";

/* -- Sidebar -- */
.sidebar {
}

.sidebar .svg-wrap {
    top: 0;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
}

.sidebar .btn-wrao {
}

.sidebar #chart-live-details {
    max-height: 400px
}

.sidebar .btn-wrao button {
    margin-left: 10px
}

.sidebar button.icon-info {
    background: transparent;
    cursor: pointer;
    color: #fff;
    font-size: 35px;
    border: 0;
    position: relative;
    top: 4px;
    padding: 1px
}

.sidebar button.icon-info span {
    display: none;
}

.sidebar button.icon-info:hover {
    color: #F59B00;
}

.sidebar button.icon-close {
    background: transparent;
    cursor: pointer;
    color: #fff;
    font-size: 35px;
    border: 0;
    position: relative;
    top: 4px;
    padding: 1px
}

.sidebar button.icon-close span {
    display: none
}

.sidebar button.icon-close:hover {
    color: #F59B00;
}

.sidebar.lft {
    font-family: "Innogy regular", sans-serif;
    background: white;
    color: $anthracite;
    min-width: 300px;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    min-height: 670px;

    border-left: none !important;
}

.sidebar.lft .blackbox {
    background: #000;
    padding: 0;
}

.sidebar.lft .logo {
    width: 300px;
    height: 200px;
    //background: url("../../assets/img/headers/menu_header.png") no-repeat;
    background-size: contain;
}

.sidebar.lft .logo.demo {
    //background: url("../../assets/img/headers/menu_header-demo.png")
}

.sidebar.lft .logo span {
    display: none
}

.sidebar.lft .version {
    position: absolute;
    left: 40px;
    bottom: 81px;
    font-size: 12px;
    z-index: 5
}
