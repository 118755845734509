@import "../fonts";

.page {
    padding: 128px 75px 75px;
    display: flex;
    flex-direction: row;

    flex: 1 1 auto;

    @include font-regular;
}

.page-card {
    width: 100%;
    background: white;
    border-radius: 8px;
    padding: 50px;
}

.page-card > section {
    margin-bottom: 50px;
}

.page-card h1 {
    @include font-bold;
    font-size: 40px;
}

.page-card h2 {
    @include font-bold;
    font-size: 32px;
}

.page-card h2 .extra-space {
    @include font-bold;
    font-size: 32px;

    margin-bottom: 40px;
}

.page-card h3 {
    margin-top: 10px;
    margin-bottom: 10px;

    @include font-bold;
    font-size: 24px;
}

.page-card ul {
    margin-bottom: 40px;

    @include font-regular;
    font-size: 24px;
}

.row > div:first-child {
    margin-right: 20px;
}

/**
 * IMPRINT SPECIFIC
 */

section .imprint {
    @include font-regular;

    h2 {
        @include font-medium
    }
}


