.center-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.inflate {
    width: 100%;
    height: 100%;
}

/**
 * MIXINS
 */
@mixin row($justify: null, $align: null, $reverse: null) {
    display: flex;

    @if $reverse {
        flex-direction: row-reverse;
    } @else {
        flex-direction: row;
    }

    @if $justify {
        justify-content: $justify;
    }

    @if $align {
        align-items: $align;
    }
}

@mixin col($justify: null, $align: null , $reverse:null) {
    display: flex;

    @if $reverse {
        flex-direction: column-reverse;
    } @else {
        flex-direction: column;
    }

    @if $justify {
        justify-content: $justify;
    }

    @if $align {
        align-items: $align;
    }
}

@mixin absolute($top: null, $left: null, $bottom: null, $right: null) {
    position: absolute;

    @if $top {
        top: $top;
    }

    @if $left {
        left: $left;
    }

    @if $bottom {
        bottom: $bottom;
    }

    @if $right {
        right: $right;
    }
}


/**
 * CLASSES
 */
.row {
    @include row;
}
