@font-face {
    font-family: "Innogy light";
    src: url("~src/assets/fonts/innogyWEB-Light.woff2") format("woff2"),
    url("~src/assets/fonts/innogyWEB-Light.woff") format("woff"),
    url("~src/assets/fonts/innogyWEB-Light.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@mixin font-light {
    font-family: "Innogy light", sans-serif;
}
.innogy-light {
    @include font-light;
}

//url("~src/assets/fonts/innogyWEB-Regular#iefix") format("eot"),
@font-face {
    font-family: "Innogy regular";
    src: url("~src/assets/fonts/innogyWEB-Regular.woff2") format("woff2"),
    url("~src/assets/fonts/innogyWEB-Regular.woff") format("woff"),
    url("~src/assets/fonts/innogyWEB-Regular.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

.innogy-regular {
    font-family: "Innogy regular", sans-serif;
}

//url("~src/assets/fonts/innogyWEB-Medium#iefix") format("eot"),
@font-face {
    font-family: "Innogy medium";
    src: url("~src/assets/fonts/innogyWEB-Medium.woff2") format("woff2"),
    url("~src/assets/fonts/innogyWEB-Medium.woff") format("woff"),
    url("~src/assets/fonts/innogyWEB-Medium.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}
.innogy-medium {
    font-family: "Innogy medium", sans-serif;
}

//url("../fonts/innogyWEB-Bold?#iefix") format("eot"),
@font-face {
    font-family: "Innogy Bold";
    src: url("~src/assets/fonts/innogyWEB-Bold.woff2") format("woff2"),
    url("~src/assets/fonts/innogyWEB-Bold.woff") format("woff"),
    url("~src/assets/fonts/innogyWEB-Bold.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}
.innogy-bold {
    font-family: "Innogy bold", sans-serif;
}

//@font-face {
//    font-family: "Innogy Icons";
//    src: url("../fonts/innogy.eot");
//    src: url("../fonts/innogy.eot?#iefix") format("embedded-opentype"),
//    url("../fonts/innogy.woff") format("woff"),
//    url("../fonts/innogy.ttf") format("truetype"),
//    url("../fonts/innogy.svg#innogy") format("svg");
//    font-weight: normal;
//    font-style: normal;
//
//}

* {
    font-family: "Innogy regular", sans-serif;
}

strong {
    font-family: "Innogy Bold", sans-serif;
}
